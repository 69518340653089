.market-place-component {
  font-family: "Poppins";
}
.market-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #021849;
}
.market-form-search-input {
  padding-left: 3rem;
  height: 50px;
  background: #eeeeee;
  border: 1px solid #eeeeee;
  border-radius: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 34px;
  outline-color: #021849;
  width: 100%;
}
.market-form-search-input::placeholder {
  color: rgba(0, 0, 0, 0.53);
}
.market-btn-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
}
.market-btn-link:hover {
  color: #ffffff;
}
.market-place-filter-container {
  border-radius: 13px;
  height: 550px;
}
.market-place-filter-container,
.filter-market-title {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.8);
}
.market-place-filter-container ul {
  margin: 0;
  padding: 0;
}
.market-place-filter-container ul li {
  list-style: none;
}
.market-form-search-seller-input {
  width: 100%;
  height: 30px;
  border: 0.5px solid #000000;
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.8);
  outline: none;
  padding: 5px !important;
}

.market-form-search-input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.form-filter-check-input {
  width: 15px;
  height: 15px;
  border: 1px solid #000000;
  border-radius: 0 !important;
}

.form-filter-check-input:checked,
.form-filter-check-input:focus {
  background: #0fbf56;
  border: 1px solid #0fbf56;
  outline: none;
  box-shadow: none;
}

/* Market Places cards */
.market-statistiques {
  background-color: #ffffff;
  border: none;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #0fbf56;
}
.market-img {
  /* height: 10vw; */
  height: 230px;
  width: 50%;
  object-fit: fill;
}
.market-place-card {
  border: 0.1px solid rgba(0, 0, 0, 0.1) !important;
  background: #ffffff;
  border-radius: 22.6091px;
}
.market-place-card:hover {
  background: #ffffff;
  box-shadow: 0px 11.3046px 120px rgba(2, 24, 73, 0.05);
  border-radius: 16.72px;
}
.market-place-card .market-place-product-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 42px;
  color: #5070b8;
}
.market-place-card .normal-price {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 42px;
  color: #000000;
  text-decoration: line-through;
}

.market-place-card .price {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 42px;
  color: #0fbf56;
}
.market-place-card .card-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #343434;
}

.card-market-btn {
  width: 100%;
  background: #f9f9f9;
  border-radius: 10px;
  border: none;
  height: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #000000;
}

.card-market-btn.card-market-btn-out {
  background: #e74141;
  color: #fff;
}
.market-place-card-link {
  text-decoration: none;
}
.product-market-go-baack-btn {
  background-color: transparent;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 34px;
  letter-spacing: 1px;
  color: #021849;
}
.produit-market-detail {
  font-family: "Poppins";
  font-style: normal;
}
.produit-market-detail .produit-title,
.produit-market-detail .price {
  font-weight: 600;
  /* line-height: 46px; */
}
.produit-market-detail .produit-title {
  color: #000000;
  font-size: 24px;
}
.produit-market-detail .price {
  font-size: 34.3784px;
  color: #0fbf56;
}

.produit-market-detail .normal-price {
  font-weight: 300;
  font-size: 14px;
  line-height: 46px;
  color: #000000;
}
.produit-market-detail .points-number,
.quantites {
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 0;
}
.increment-btn-container {
  border: 1px solid #9d9696;
  border-radius: 10px;
  width: 14%;
  margin-top: 5px;
}
.product-btn-shop-command {
  border: none;
  background: #0fbf56;
  border-radius: 10px;
  font-family: Poppins;
  /* font-size: 12px; */
  font-size: clamp(11px, 1vw, 26px);
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #ffffff;
  padding: 0.7rem 4rem;
}
.card-product-market-btn {
  border: 1px solid rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.7);
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1em;
  padding: 0.7rem 4rem;
}
.card-product-market-btn.out {
  border: 1px solid #e74141;
  color: #e74141;
}
.product-desc-container {
  background: rgba(2, 17, 52, 0.03);
  border-radius: 10px;
  font-family: Poppins;
  letter-spacing: 0em;
  text-align: left;
}
.product-desc-container .desc-product-title {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
}
.product-desc-container .desc-product-body {
  font-size: 15px;
  line-height: 31px;
  font-weight: 300;
}
.notif-modal-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #000000;
}

.custom-produit-description {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-card-img-detail-produit {
  max-width: 280px;
  height: 220px;
}
.produit-market-img-container {
  padding: 15px 25px;
}
