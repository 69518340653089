.icon-user-acount-dashboard {
  color: #768492;
  width: 18px;
  margin-left: 5px;
}

.user-acount-dashboard-header {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #011f3b;
  background: none;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
}
.image-user-acount-dashboard-header {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}
button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}
.h-logo-container {
  text-align: center;
}
img.logo-header {
  width: 100px;
  border-radius: 50%;
}
.headering {
  background: #021134;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  padding: 20px 200px;
  display: flex;
  align-items: center;
  width: 100%;
}
.navbar-brand-costum {
  padding: 0;
}
.div-content-header-contact {
  background: #f4efee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}
.menu-link-contact-us {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.468947px;
  color: #ffffff !important;
}
.btn-icon-menu-header {
  color: #000;
}
.btn-icon-menu-header:hover {
  color: #14b253;
  transition: 0.3s ease-in-out;
}
.text-langage {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.468947px;
  color: #000000;
}
.img-social-icon-header {
  width: 19.84px;
  height: 19.84px;
  margin-left: 10px;
  margin-right: 10px;
}
.menu-item {
  list-style: none;
  float: left;
  padding: 0 21px;
  position: relative;
}
.menu-link {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.468947px;
  color: #ffffff !important;
  text-decoration: none;
}
.menu-link.active:after {
  content: "";
  width: 40px;
  height: 4px;
  background: #14b253;
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: auto;
}
.menu-link.active {
  font-weight: bold;
  color: #14b253;
}
a.menu-link:hover {
  color: #14b253;
  text-decoration: none;
}
.bloc-icon-menu {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 15%;
}

.btn-langue-header {
  padding: 0;
}
.dropdown-toggle::after {
  border-top: 0.2em solid !important;
  border-right: 0.2em solid transparent !important;
  border-left: 0.2em solid transparent !important;
  vertical-align: 2px !important;
}
.link-item-header {
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding: 0 20px;
}
.link-item-header:hover {
  color: inherit;
  font-weight: bold;
}
.menu-link-select-header {
  border: none;
  background: inherit;
}
.dropdown-item-menu-header {
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 154.4%;
  color: #000000;
}
.dropdown-item-menu-header:hover {
  background: #14b253;
  color: #fff;
}
.dropdown-item-menu-header:focus {
  background: #14b253;
  color: #fff;
}
.container-nav-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100vw; */
  /* height: 100vh; */
}
.sidebar {
  position: fixed;
  width: 250px;
  left: -250px;
  height: 100%;
  background-color: #fff;
  transition: all 0.5s ease;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  top: 0;
  z-index: 9999;
}
.sidebar header {
  padding: 20px 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #14b253;
  user-select: none;
  margin-top: 16%;
  display: flex;
  align-items: center;
  margin-left: 19px;
}
.sidebar .nav-menu {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8a8a8a;
  padding-left: 30px;
  box-sizing: border-box;
  /* border-left: 5px solid transparent; */
  transition: all 0.5s ease;
  text-decoration: none;
}
/* .nav-menu.active,.nav-menu:hover{
    border-left: 5px solid var(--accent-color);
    color: #fff;
     background: linear-gradient(to left, var(--accent-color), var(--gradient-color));
  } */
.nav-header-menu {
  padding-left: 15px;
}
.sidebar .nav-menu i {
  font-size: 23px;
  margin-right: 16px;
}
.sidebar .nav-menu span {
  letter-spacing: 1px;
  padding-left: 15px;
}
#check {
  display: none;
}
label #btn,
label #cancel {
  position: absolute;
  left: 5px;
  top: 23px;
  cursor: pointer;
  margin: 15px 30px;
  height: 23px;
  width: 29px;
  transition: all 0.5s ease;
}
label #cancel {
  opacity: 0;
  visibility: hidden;
}
#check:checked ~ .sidebar {
  left: 0;
}
#check:checked ~ label #btn {
  margin-left: 245px;
  opacity: 0;
  visibility: hidden;
}
#check:checked ~ label #cancel {
  margin-left: 250px;
  opacity: 1;
  visibility: visible;
  z-index: 999999;
  color: white;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
.cnt-dropdown-toggle {
  z-index: 99;
}
.btn-login {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: #14b253;
  background: transparent;
  border: none;
  text-align: center;
}

.sidebar > .nav-menu.active,
.sidebar > .nav-menu:hover:nth-child(even) {
  --accent-color: #52d6f4;
  --gradient-color: #c1b1f7;
}
.sidebar .nav-menu.active,
.sidebar > .nav-menu:hover:nth-child(odd) {
  --accent-color: #c1b1f7;
  --gradient-color: #a890fe;
}
.icon-nav-menu-mobile {
  width: 16.67px !important;
  height: 16.67px !important;
}
.dropdown-item-custom.active {
  background: #14b253 !important;
}
.container-logo {
  margin-left: 19%;
}
.container-logo img {
  width: 76px;
  height: 38px;
}
#scrollbar-nav-mobile {
  height: 100%;
  overflow-y: auto;
}
#scrollbar-nav-mobile::-webkit-scrollbar {
  display: none;
}
.content-nav-mobile {
  height: 350px;
  /* height: 90px; */
}
/* .form-select-exploitation {
    background: #FFFFFF;
    border: 1px solid #14543C;
    box-sizing: border-box;
    border-radius: 10px;
    border-radius: 10px;
    padding: .375rem 1.2rem !important;
    background: url(../../../images/icons/Shape.png) 90% 47% / 13px no-repeat #fff !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: #5A5A5A;
} */
.form-select:focus {
  box-shadow: none !important;
  border-color: none !important;
}
#dropdown-header .dropdown-toggle::after {
  display: none;
}
.notif-statut {
  position: absolute;
  left: 59%;
  top: 53%;
  background: #db0909;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.navbar-light .navbar-toggler-icon {
  background-image: url(../../assets/icons/Vector.png) !important;
}

.dropdown-btn-logged-user-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.468947px;
  color: #ffffff;
}

.dropdown-toggle::after {
  color: #ffffff;
}

.dropdown-item-custom {
  color: #021134;
}
.dropdown-item-custom:hover {
  background-color: #14b253;
  color: #ffffff;
}

.custom-position-absolute {
  position: absolute !important;
  top: 8px !important;
  right: -3px !important;
  width: 17px;
  height: 17px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  background: #ffffff;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  border: 1px solid #021849;
  color: #121212;
}
.custom-position-absolute-admin {
  top: 7px !important;
  right: -12px !important;
}

@media (max-width: 767px) {
  .content-nav-mobile {
    /* height: 350px; */
    height: 90px;
  }
}
