body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fbfbfb;
}

.btn-rs-facebook {
  border: none;
  background-color: transparent;
}

.btn-rs-facebook i {
  font-size: 20px;
}

@font-face {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Bold"), url("./fonts/Gilroy-Bold.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Heavy"), url("./fonts/Gilroy-Heavy.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Light";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Light"), url("./fonts/Gilroy-Light.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Medium"), url("./fonts/Gilroy-Medium.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Regular"),
    url("./fonts/Gilroy-Regular.woff") format("woff");
}

.react-multi-carousel-dot-list {
  bottom: -30px !important;
}
/* .react-multi-carousel-list{
  overflow: initial !important;
} */
.react-player__preview {
  border-radius: 10px;
}
.avatar-container {
  margin-top: 9%;
}
.dashboard-page-content {
  margin-top: 7%;
}
.maajjal-table-card table tbody tr td {
  padding: 20px 0;
}
section.details-projet-infos-table-container table tbody tr td {
  padding: 20px 10px;
}
.react-bootstrap-table.table-responsive,
.react-bootstrap-table-pagination {
  padding: 20px;
}
table tbody tr td {
  padding: 20px 10px !important;
}
.commercant-dashboard-stat-row .ma-dashboard-card-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.commercant-dashboard-stat-row .dashboard-stat-block-header {
  height: 27px;
}
.btn-profile-submit {
  width: 100%;
}
.app-logo-and-version-container {
  position: relative;
}
.version-viewer-container.app-side {
  position: absolute;
  color: #ffffff;
  font-size: 12px;
  bottom: 0%;
  right: -30px;
  font-weight: 600;
}
.logo-container {
  position: relative;
}
.version-viewer-container.admin-side {
  position: absolute;
  color: #ffffff;
  font-size: 12px;
  bottom: 10%;
  right: 80px;
  font-weight: 600;
}
.version-viewer-container.commercant-side,
.version-viewer-container.admin-desktop-side {
  position: absolute;
  color: #ffffff;
  font-size: 12px;
  bottom: 9%;
  right: 60px;
  font-weight: 600;
}

.page-item .page-link {
  z-index: 3;
  color: rgb(10, 42, 66);
  background-color: #fff;
  /* border-color: rgb(10, 42, 66); */
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: rgb(10, 42, 66) !important;
  border-color: rgb(10, 42, 66) !important;
}

.select-perpage {
  color: #fff !important;
  background-color: rgb(10, 42, 66) !important;
}
.ck-content {
  min-height: 150px !important;
}

.btn-float-whatsapp {
  border-radius: 50%;
  bottom: 4%;
  position: fixed;
  right: 4%;
  z-index: 99;
  transform: height 2s linear;
}
.btn-float-whatsapp:hover img {
  width: 105px !important;
  height: 105px !important;
}

.form-select-majjal {
  width: 100%;
  background-color: #ffffff;
  outline-color: #1a91da;
  appearance: none;

  background: #fff;
  padding: 20px;
  background: url(./components/assets/icons/chevron-down.png) 99% 47% / 13px
    no-repeat #fff !important;
}
